import React from "react";
import {graphql, Link, navigate, useStaticQuery} from "gatsby";
import BlogFeed from "../components/blog-feed";
import DownloadForm from "../components/download-form";
import Layout from "../components/layout";
import Seo from "../components/seo";
import workshopsUserPersonas from "../images/workshops-user-personas/featured-image-user-flow-persona.png";
import {createContact} from "../api";
import {buildUTMCustomFields} from "../helpers/activeCampaignCustomFieldsBuilder";

const mailingListId = process.env.GATSBY_ACTIVE_CAMPAIGN_729_SOLUTIONS_MAILING_LIST_ID;
const subscriberUserPersonaWorkshopFormTag = process.env.GATSBY_SUBSCRIBER_USER_PERSONA_WORKSHOP_FORM_TAG


const Main = () => (
  <>
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${workshopsUserPersonas})`,
          maxHeight: "56vw",
          height: "80vh",
        }}
      ></div>
    </section>
    <section>
      <div className="container pb-6">
        <div className="row">
          <div className="col mt-n5">
            <div className="card custom-cards card-border">
              <div className="card-body">
                <h1 className="m-0">User Persona Creation Process</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-light">
      <div className="container py-6">
        <div className="row">
          <div className="col">
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2>User Personas Paint Pictures To Drive Business Decisions</h2>
            <p>
              User Personas represent who will be interacting with your product
              or service. A user persona isn't restricted to the final consumer
              who purchases your product (that would be a buyer persona) but can
              be created for any role that will interact with your product or
              service along its journey. User personas are instrumental in
              creating{" "}
              <Link to="/quick-user-testing-guide/">a strong user testing</Link>{" "}
              plan or deciding how to layout your user{" "}
              <Link to="/what-is-user-interface-design/">interface (UI)</Link>.
            </p>
            <p>
              Have you ever thought about how creating user personas and
              understanding their unique user journeys can contribute to
              important business decisions you need to make? Fully understanding
              users and their journey helps to create a final product that is
              streamlined, efficient, and easy to use – helping each user access
              the information and resources that are truly needed for where they
              are in their journey.
            </p>
            <h2>Discovering Opportunity For Sundance Institute</h2>
            <h3>Introduction</h3>
            <p>
              <a href="https://www.sundance.org/">The Sundance Institute</a> had
              a problem. They're home to one of the most well-known film
              festivals in the world, the Sundance Film Festival, but few people
              seemed to know of their work beyond the film festival itself. They
              wanted to spread the word about all of the other amazing work that
              the Sundance Institute does for its artists and the film industry
              but struggled with communicating this to their audience.
            </p>
            <p>
              They knew they needed to improve the usability of their website
              and provide a smooth interface for film lovers and festival
              attendees alike. It was also important that the website match the
              brand identity of the Sundance Institute (as compared to the
              identity of the film festival alone), and also provide an engaging
              online experience in a world of overstimulation.
            </p>
            <p>
              To help the Sundance Institute understand how to reach their goal,
              we knew that one of the most critical first steps would be to
              identify the various users who would be engaging with the website.
              Using information provided directly from the Sundance Institute in
              addition to some external research, 729 developed eight user
              personas that, on the whole, represented people who would be
              interacting with the Sundance Institute website in different
              capacities.
            </p>
            <h3>User Versus Buyer Personas</h3>
            <p>
              Let's take a moment here to talk a bit more about the difference
              between user personas and buyer personas, and when one or the
              other might be more useful. For the Sundance Institute, the team
              specifically wanted to make user personas; that is a persona for
              any entity that would be engaging with their website. These
              weren't only the end-user of their products, such as festival
              attendees, but literally, ANYONE who would interact with the
              website.
            </p>
            <p>
              For the Sundance Institute that meant focusing not just on
              festival attendees (the "buyers"), but also on all of the other
              people who will interact with the site. These are entities who
              will USE the Sundance Institute's website but won't necessarily be
              BUYING a product or service as a result of that use. Some of these
              users are looking for information (how to enter a film into a
              festival, where to buy festival tickets, how to volunteer) while
              others might be looking to submit a request (submit a film,
              sponsor an event, request a press pass). User persons focus
              specifically on how a particular user group will interact with the
              site – where were they before they came to the site, what are
              their goals when they are on the site, and where will they go
              after they leave the site. Focusing on these specifics allows us
              to understand how to best design a User Interface and create an
              experience that will meet their needs.
            </p>
            <h3>Defining User Personas</h3>
            <p>
              The Sundance Institute had a good idea who many of their users
              were (artists and festival attendees), but they knew they were
              missing a subsection of their users. We used a unique two-fold
              approach to gathering data and information to further define their
              user personas.
            </p>
            <p>
              First, we started with traditional data points that the Sundance
              Institute already had from their regular business practices. This
              included their annual report, as well as the surveys and reports
              that were created after each Sundance Institute festival or event.
              Additional data was gathered using Google Analytics website
              engagement statistics. The Sundance Institute interviews their
              artists at important milestones, and these interviews were also
              reviewed and considered.
            </p>
            <p>
              The second leg of data gathering focused on internal departments
              at the Sundance Institute. Each department completed a User
              Persona workshop with the 729 UI/UX team, which provided crucial
              information about the goals, hopes, and pain points for a variety
              of internal stakeholders. This helped to ensure that the
              information and data used to define the user personas were fully
              representative of the Sundance Institute's broad audience,
              including donors, volunteers, festival locals, and industry
              representatives.
            </p>
            <p>
              For the Sundance Institute, eight such user types were identified:
            </p>
            <ul className="text-primary">
              <li>
                <span className="text-dark">Artists</span>
              </li>
              <li>
                <span className="text-dark">Funders</span>
              </li>
              <li>
                <span className="text-dark">Press</span>
              </li>
              <li>
                <span className="text-dark">Industry representatives</span>
              </li>
              <li>
                <span className="text-dark">Volunteers</span>
              </li>
              <li>
                <span className="text-dark">Utah locals</span>
              </li>
              <li>
                <span className="text-dark">Other festival attendees</span>
              </li>
              <li>
                <span className="text-dark">Internal Sundance staff</span>
              </li>
            </ul>
            <h3>Taking The User Personas Through Their Unique User Journeys</h3>
            <p>
              Once we had a strong grasp on who the user personas were, it was
              time to jump into their shoes and follow their unique{" "}
              <Link to="/workshops-user-flow/">user journeys</Link>. For each
              persona, it was important to fully understand how they fit into
              the Sundance Institute's broader organizational chart and what
              their likely actions would be while engaging with the Sundance
              Institute's website, festivals, and other services.
            </p>

            <p>
              For each persona that was identified, we started on the most
              likely page of the Sundance Institute's website that a user would
              enter on, based on analytics history. Their journey was hashed out
              from there. Each persona has different needs that are the driving
              force behind what links or buttons they clicked on the website.
              For example, an aspiring artist might be looking for information
              on how to enter one of the Sundance Institute's film festivals,
              while a donor might be looking for an easy way to make a
              contribution online.
            </p>

            <p>
              Spending this time on identifying and mapping out the user
              journeys is important to help each user have a frustration-free
              experience with the Sundance Institute's website, and ultimately
              with their products and brand. Users want their experience to be
              smooth, seamless, and straightforward; fleshing out their user
              journeys helps make this possible.
            </p>
            <h3>Creating Wireframes And Mockups</h3>
            <p>
              Once the team had laid out the journey that each user persona
              might take it was time to start creating{" "}
              <Link to="/workshops-wireframes/">wireframes and mockups</Link> for
              the various web pages, forms, and calls-to-action that were
              needed. Since we had clearly identified not only who would be
              using the website, but also how they would use it, the wireframe
              creation process was both straightforward and speedy.
            </p>
            <p>
              The UI/UX team not only knew what each wireframe should be, but
              they also knew what wireframes they didn't need. No time was
              wasted on creating wireframes and mockups that would ultimately be
              discarded as they had already identified how a person would move
              throughout the Sundance Institute's website.
            </p>
            <p>
              In addition to improved efficiency, the team worked with the
              confidence that the user persona and user journey mapping meant
              they were dialed into specific needs that any particular group of
              users might have. For example, knowing that potential donors and
              potential artists would interact with the website differently,
              taking two different user journeys, specific wireframes were
              created for each group. Additionally, superfluous text that didn't
              apply to a specific user persona was eliminated and the whole
              process was streamlined leading to an improved user experience.
            </p>
            <h3>Revealing The Final Website</h3>
            <p>
              The final website for the Sundance Institute is supercharged to
              emphasize the user experience and user journey. 729 Solution's web
              design team took the site to an entirely new level of usability
              and engagement; users now had a clear path through the site that
              was tailored for their needs and kept them focused on their
              purpose for being there.
            </p>
            <h4>Example #1 – Aspiring Artists</h4>
            <p>
              Aspiring artists frequently go to the Sundance's Institute website
              looking for information on how to enter one of their festivals. At
              this point, they're typically quite hungry for information and
              will spend significant time exploring the website in their quest
              to learn more and dream of their future success as a filmmaker.
            </p>
            <p>
              As 729's UI/UX team talked through the aspiring artist persona
              with the Sundance Institute, they discovered that these aspiring
              artists really were taking a deep dive into the Sundance website.
              The main places they were visiting were general information pages,
              the Sundance Institute's blog, and pages about specific programs
              and events. Additionally, they were seeking connections with other
              aspiring artists through the online connection and collaboration
              portal
              <a
                href="https://collab.sundance.org/"
                target="_blank"
                rel="noreferrer"
              >
                (Co//ab)
              </a>
              .
            </p>
            <p>
              Using this information, we suggested that the Sundance Institute
              really focus their language in these various online locations to
              spread the message that they are more than just a film festival.
              They added information and links to their blog, connection portal,
              and general information pages so that when an aspiring artist
              visited one of those pages, they would also see and hopefully
              click on the additional information, in turn learning more about
              the Sundance Institute as a whole. Only by understanding the full
              user journey that an aspiring artist would take when visiting
              their website were they able to dial in their messaging to
              specifically expand a users understanding of what the Institute
              is.
            </p>
            <h4>Example #2 – Corporate Sponsors</h4>
            <p></p>

            <p>
              The Sundance Institute is in part supported by their corporate
              sponsors. These companies, both large and small, are looking to
              exchange their support for visibility to other users of the
              Sundance Institute's services and products, most often through
              their film festivals. However, film festivals are not the only way
              that a corporate sponsor can work with the Sundance Institute. As
              such, they wanted to focus on educating their sponsors about all
              of the other programs and events in their portfolio.
            </p>

            <p>
              While working on the user persona for corporate sponsors it became
              clear to us that there were certain pages on the Sundance
              Institute's website where they would go for information. These
              pages were drastically different from the pages visited by their
              other personas (such as aspiring artists or festival attendees).
            </p>

            <p>
              Corporate sponsors (both existing and potential) visited pages
              that gave background information (the about us page), event and
              program information, and information about specific up-and-coming
              artists. They were not visiting pages with information on
              submitting a film or attending a festival but instead focused on
              learning more about the background of the Sundance Institute to
              ensure the institute's mission came into alignment with their own.
            </p>

            <p>
              The user persona workshop and the user journey meant that it was
              clear which pages the corporate sponsors were visiting, so they
              knew where they should direct their marketing efforts. They added
              information about the other activities of the Sundance Institute
              to each of the pages that the corporate sponsor persona was likely
              to view, thus increasing the possibility that they would learn
              more about the depth of the Sundance Institute's events, programs,
              and services. Because they knew exactly where these corporate
              sponsors would go, they knew which pages to place their marketing
              messaging on.
            </p>
            <h4>Example #3 – Festival Attendees</h4>
            <p>
              Festival attendees are obviously one of the Sundance Institute's
              largest audiences. These attendees are often devoted to the art of
              film and love learning more about their favorite artists and their
              works. The festival attendees are also a captive audience since
              they will be watching a film (either in person or online in 2020),
              leading to easy opportunities to insert a brief marketing message
              before or after the cinematic presentation.
            </p>
            <p>
              Additionally, festival attendees have the greatest likelihood of
              overlapping with other user personas. The attendees are most
              likely to also contribute as a donor, enter their own film into a
              festival, or volunteer to help at an event. However, they often
              don't fully realize the programs and services offered by the
              Sundance Institute beyond the film festivals.
            </p>
            <p>
              Using this information, our team helped the Sundance Institute
              create spaces for clear messaging that could be shown to the
              festival attendees throughout the ticket purchasing and
              fulfillment process, that would help attendees connect with the
              other programs the Institute offers. We assured the marketing team
              that the messaging would not need to have a “marketing” feel to
              it, as they were just providing useful information to people who
              have already bought in on the value of the Sundance brand. You
              could be straightforward and to the point with these users,
              getting your message across quickly and easily. Festival attendees
              were hungry for more information about the Sundance Institute,
              having already fallen in love with the film festivals, and were
              excited to learn about more ways to engage.
            </p>

            <h2>Discovering The Unexpected For Bundle</h2>
            <h3>Introduction</h3>
            <p>
              <a
                href="https://bundleloan.com/"
                target="_blank"
                rel="noreferrer"
              >
                Bundle
              </a>{" "}
              came to 729 Solutions with a new business concept that they needed
              help taking from idea to reality. Bundle knew that buying a home
              was a huge goal for many people, but that often the act of
              securing funding and undertaking a mortgage was confusing and
              overwhelming. For first-time homebuyers who had never obtained a
              mortgage before the entire process can feel disjointed and
              perplexing; they know it's a serious and important process, but
              struggle to understand how it all really works. Bundle's product
              focuses on creating clarity and transparency in the mortgage
              purchasing process while protecting users' concerns for privacy
              and information security.
            </p>
            <h3>Defining User Personas</h3>
            <p>
              Bundle began the user persona process with the presumption that
              they only had one or two different personas. As we started the
              deep dive into understanding their services, however, additional
              user personas became clear.
            </p>
            <p>
              As a mortgage solution product, it was clear that a “homebuyer”
              was the prominent user persona from the get-go. Homebuyers are the
              ones who are gathering information about the mortgage process and
              entering their own personal information to determine what mortgage
              options are available to them. As the team worked, however, the
              differences between a first-time homebuyer and a repeat homebuyer
              began to emerge.
            </p>
            <p>
              For Bundle, the team also identified separate user personas in the
              real estate agents and the mortgage companies that would be
              engaging with the product from the professional side. While these
              entities are clearly well versed in how the mortgage process
              works, it was still important to begin thinking about what their
              unique needs are and how these needs could be met through a
              tailored experience that Bundle would later offer.
            </p>
            <h3>Exploring The User Journey</h3>
            <p>
              Once the user personas were identified it was time to begin
              exploring the <Link to="/workshops-user-flow/">user journey</Link>.
              The information that was uncovered during the user persona
              generation process became critical in thinking about how potential
              users would be navigating the website.
            </p>
            <p>
              Where Bundle had started with a single persona for homebuyers,
              splitting that out into first-time and repeat homebuyers allowed
              us to tailor their journey even more specifically. For a repeat
              homebuyer, they might prefer to hover or click over terms to get a
              reminder about definitions and concepts. They know the basics, and
              adding simple hints gave them the option for more information
              without cluttering their main screens. For new home buyers, the
              team knew they might prefer to get more information upfront,
              without having to hover or click. As such, the user journey
              through Bundle's mortgage solution process would look different
              for each person, despite them both being homebuyers.
            </p>
            <h3>Developing Wireframes And Mockups</h3>
            <p>
              Once the user journeys were mapped out it was time to begin the
              process of making wireframes and mockups. Again, the information
              discovered during the user persona process was critical to making
              sure unique needs were being met.
            </p>
            <p>
              Since the mortgage process involves lots of numbers and
              complicated calculations, it was important that the wireframes
              demonstrated how the information would be presented in a clear and
              succinct format. Our team was able to tailor each set of
              wireframes for the specific user personas, making sure people saw
              the information that was relevant to their situation without being
              overwhelmed by too much information that they didn't need at that
              point in time.
            </p>
            <h3>The Final Product</h3>
            <p>
              Bundle was a big project with lots of complicated calculations and
              intricacies that form the backbone of the home buying process in
              the United States. Additionally, working with financial
              information and personal details mean that data security was a top
              priority: no one wants their personal financial information stolen
              or used in a harmful way.
            </p>
            <p>
              Creating and utilizing user personas meant that Bundle could
              address the concerns of their various constituents (buyers,
              lenders, brokers) with pinpoint precision. When you come to the
              website, it is easy to self-identify as to which category you are
              in and then click to follow the apparent path that has been set
              out for your needs. Users see only the information that is
              pertinent to them based on what user journey they are on; buyers
              don't see information specific to mortgage brokers and vice versa.
            </p>
            <h3>
              Business Decisions Bundle Made Based On The Discovery Process
            </h3>
            <h4>Example #1 – Mortgage Agencies</h4>
            <p>
              The founder of Bundle had an extensive background within the
              mortgage industry. He understood how the mortgage process worked
              from start to finish and knew how to explain the complex financial
              and regulatory information that envelops the mortgage process.
              When he came to 729 Solutions, he had already put significant time
              into thinking about who his users were, and how they would use the
              new service (Bundle) that he was creating.
            </p>
            <p>
              When we began working through the user persona process with
              Bundle, they started with the personas that were easily
              identifiable: a homebuyer, a real estate agent, and a mortgage
              loan officer. As they talked through each persona and discussed
              what that hypothetical person would be looking for, a new and
              unexpected persona emerged: the mortgage agency.
            </p>
            <p>
              Originally, the Bundle business model focused on helping
              individual home buyers and their real estate agents connect with
              individual mortgage loan officers. Each loan officer (and each
              buyer and real estate agent) would have their own unique Bundle
              login and information. Then the Bundle team realized there was an
              entire group that they'd originally missed when thinking about
              possible users; the mortgage agency!
            </p>
            <p>
              Many mortgage loan officers work within the context of a larger
              agency. While the officer is the one working directly with
              homeowners and realtors, they're doing so under the larger
              umbrella of the agency. By making the mortgage agency their own
              entity as a user persona, Bundle had to further think about how
              they could market their product to an agency as a whole, and how
              the user journey might change when navigating through an agency
              portal.
            </p>
            <p>
              Identifying the mortgage agency itself as a separate user persona
              opened up an additional avenue of revenue for Bundle. Bundle was
              able to market to them specifically, and with a more efficient
              marketing strategy. By focusing on the agency, Bundle could
              demonstrate that instead of having to attract 30 separate loan
              officers, working with an agency would automatically attract all
              loan officers within that agency. This meant Bundle could have
              more of an impact with less marketing efforts.
            </p>
            <p>
              While they can (and do) still promote themselves to individual
              mortgage loan officers, they also promote themselves directly to
              multi-person agencies. Since they mapped out what a mortgage
              agency would need and how they would navigate the Bundle website,
              they were well prepared to answer any questions and remove any
              barriers to using their mortgage solution product.
            </p>
            <p>
              This new persona of a mortgage agency felt like a big step forward
              in terms of how Bundle could imagine their future success.
              Mortgage agencies could register all of their loan officers,
              manage their information, and remove them as needed. Registering
              loan officers also gives the potential to increase their reach to
              clients, as loan officers refer their clients to Bundle as a
              resource for information about the loan buying process.
            </p>
            <h4>Example #2 – First Time Versus Repeat Homebuyers</h4>
            <p>
              Bundle's second discovery came while working on one of the user
              personas that was originally identified; the homebuyer. The
              homebuyer is the most obvious user persona when looking at the
              mortgage loan process. The homebuyer is responsible for entering
              all of their personal and financial information correctly and
              bears the ultimate responsibility for the repayment of the
              mortgage loan on their new home.
            </p>
            <p>
              Along with the team at Bundle, we drew on their own experiences
              with the home buying process (along with other data and research)
              to help guide both the user persona and the user journey discovery
              processes. What came to light is that not every homebuyer is the
              same, and their needs may vary drastically from one person to
              another.
            </p>
            <p>
              First-time homebuyers might be at a completely different stage of
              life and have little experience making large purchases that
              require complicated financial calculations and decisions. There
              are many new terms and concepts for them to learn that they've
              likely never encountered before. They need more detailed
              information, as well as having the information presented to them
              in a straightforward manner.
            </p>
            <p>
              When they begin the process of buying a home, the first-time
              homebuyer likely has little understanding of the mortgage loan
              application process. They know it's complicated, and might even be
              a little fearful of making a mistake during this complex legal and
              financial process. This first-time homebuyer is going to need more
              in-depth information right from the start. They will have
              questions about the timeline of the mortgage process, the
              definitions used by their realtor or mortgage officer, and
              concerns about the security of their financial data. They come
              from a place of knowing nothing about the home buying process,
              which means Bundle has an opportunity to educate and guide them.
            </p>
            <p>
              In contrast, a repeat homebuyer is likely to have different needs.
              Having purchased a home before, they're already familiar with the
              basics of the timeline, process, and paperwork needed to secure
              their mortgage. They don't require the same level of support, and
              in fact, might become frustrated if basic information that they
              already know is forced on them. Instead, they want to choose what
              information they get a refresher course on and what information
              they know well enough to skip.
            </p>
            <p>
              For the repeat homebuyer, the user journey might flow at a quicker
              pace, with in-depth information available, but not visible, using
              hover or drop-down UI elements, for example. This way the user can
              access additional helpful information if they want it, but it's
              not an impediment to their navigating the website and entering
              their information quickly.
            </p>
            <p>
              This small tweak of separating first-time from repeat homebuyers
              resulted in the creation of two distinct user personas. While
              they're both working towards the same end goal of purchasing a
              home, the process by which they get there will be different. Armed
              with this information, Bundle set up their website in a way that
              allows each persona to get the information that is most useful to
              them, and to skip information that they don't need.
            </p>

            <h2>Conclusion</h2>
            <p>
              Many companies attempt to create user personas as a matter of
              course when they're first developing their website. They know it's
              important to understand who is using their website and how those
              people identify. However, often companies won't take this
              information to the next level by utilizing their user persona
              knowledge to help shape business decisions.
            </p>
            <p>
              729 Solution's UI/UX team has years of experience helping
              companies make connections between the user personas, user
              journeys, and business decisions. These two examples highlight how
              each company, The Sundance Institute, and Bundle, developed their
              user personas and then used this information to make business
              decisions. By following this process, and by working with a strong
              UI/UX team, each company was able to improve their website and
              services, laser focus their messaging and where it appeared, and
              ensured that each user was having a premium experience tailored to
              their specific needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
);

const Index = ({location}) => {

  const handleOnDownload = async (email) => {
    const customFields = buildUTMCustomFields(location.search);
    await createContact({
      contact: {email}, mailingListId, tags: [subscriberUserPersonaWorkshopFormTag], customFields
    })
    navigate('../../workshops-user-personas-ty');
  }


  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="User Persona Creation Process"
        description="Creating user personas is critical to serving your customers. 729 Solutions User Persona Creation workshops makes it easy to create and utilize user personas."
      />
      <Main/>
      <section className="bg-dark text-center py-6 px-md-6 mb-6">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-white">User persona workshop</h2>
              <p className="text-white">
                User Personas paint the pictures that you need to create your
                business. Click below to download our white paper on how we helped
                two amazing companies make better business decisions using our
                user persona discovery process.
              </p>
              <DownloadForm onSubmit={handleOnDownload}/>
            </div>
          </div>
        </div>
      </section>
      <BlogFeed containerClassname="px-0"/>
    </Layout>
  )
};

export default Index;
