import PropTypes from "prop-types";
import React, {useState} from "react";
import Col from 'react-bootstrap/Col'
import {Alert, Button, Form} from "react-bootstrap";
import {validEmail} from "../helpers/validations";
import {validateReCaptcha} from "../api";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const DownloadForm = ({onSubmit}) => {
  const [validated, setValidated] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formInfo, setFormInfo] = useState({
    email: {value: "", valid: false},
  });
  const { executeRecaptcha } = useGoogleReCaptcha();


  const isValid = (form) => {
    const {email} = formInfo;
    return form.checkValidity() && email.valid;
  }

  const handleSubmit = async (event) => {
    const {currentTarget: form} = event;
    event.preventDefault();
    event.stopPropagation();
    if (isValid(form)) {
      try {
        const reCaptchaToken = await executeRecaptcha();
        await validateReCaptcha(reCaptchaToken);
        await onSubmit(formInfo.email.value);
      } catch (error) {
        setShowErrorMessage(true)
      }
    }
    setValidated(true);
  };


  return (
    <>
      {showErrorMessage && <Alert variant="danger">
        Something went wrong, please try again later!
      </Alert>}
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="mb-4"
      >
        <Form.Row>
          <Col lg={9} md={8}>
            <Form.Group className="required">
              <Form.Control
                type="email"
                placeholder="E-mail"
                required
                isInvalid={
                  validated &&
                  (!formInfo.email.valid || formInfo.email.value.length === 0)
                }
                isValid={
                  validated && formInfo.email.valid && formInfo.email.value.length > 0
                }
                onBlur={(event) => {
                  setFormInfo({
                    ...formInfo,
                    email: {
                      value: event.target.value,
                      valid: validEmail(event.target.value),
                    },
                  });
                }}
              />
              {!formInfo.email.valid ? (
                <div className="mt-2 invalid-feedback text-left">
                  Please enter a valid email.
                </div>
              ) : null}
              {formInfo.email.value.length === 0 ? (
                <div className="mt-2 invalid-feedback text-left">
                  Please complete this required field.
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col lg={3} md={4}>
            <Button type="submit" className="w-100 mt-1 text-center">DOWNLOAD</Button>
          </Col>
        </Form.Row>
      </Form>
    </>
  );
};

DownloadForm.propTypes = {
  buttonText: PropTypes.string,
  showHowYouFindUs: PropTypes.bool,
};

export default DownloadForm;
